import { useFormContext } from "react-hook-form";
import { QUICK_FILTERS_LIST } from "../constants";
import { useCallback } from "react";

const QuickFilter = () => {
  const { watch, setValue,getValues} = useFormContext();
  const methods = useFormContext();
  const handleQuickFilterClick = (value: string) => {
    if (!watch("quickFilters")) {
      setValue("quickFilters", [value]);
      return;
    }
    const selectedFilters: string[] = watch("quickFilters");
    if (watch("quickFilters")?.includes(value)) {
      const index = selectedFilters.findIndex((el) => el === value);
      selectedFilters.splice(index, 1);
      setValue("quickFilters", [...selectedFilters]);
    } else {
      setValue("quickFilters", [...selectedFilters, value]);
    }
    handleFilters()
  };

  const handleFilters = useCallback(() => {
    let marketStatusFilters = getValues("marketStatus");
    let ownerTypeFilters = getValues("ownershipFilter.ownerType");
    const quickFilters = methods.watch("quickFilters");

    if (quickFilters?.includes("ON_MARKET")) {
      marketStatusFilters.push("ON_MARKET");
    } else {
      marketStatusFilters = [];
    }
    methods.setValue("marketStatus", marketStatusFilters);

    const updateOccupancy = quickFilters?.includes("OWNER_OCCUPIED")
      ? quickFilters.includes("VACANT")
        ? ["OWNER_OCCUPIED", "VACANT"]
        : ["OWNER_OCCUPIED"]
      : quickFilters?.includes("VACANT")
      ? ["VACANT"]
      : [];
    if (
      updateOccupancy.length !==
        methods.getValues("ownershipFilter.occupancy")?.length ||
      !updateOccupancy.every(
        (val, index) =>
          val === methods.getValues("ownershipFilter.occupancy")[index]
      )
    ) {
      methods.setValue("ownershipFilter.occupancy", updateOccupancy);
    }

    const absenteeOwner = quickFilters?.includes("IN_STATE_ABSENTEE_OWNER")
      ? quickFilters.includes("OUT_OF_STATE_ABSENTEE_OWNER")
        ? ["IN_STATE_ABSENTEE_OWNER", "OUT_OF_STATE_ABSENTEE_OWNER"]
        : ["IN_STATE_ABSENTEE_OWNER"]
      : quickFilters?.includes("OUT_OF_STATE_ABSENTEE_OWNER")
      ? ["OUT_OF_STATE_ABSENTEE_OWNER"]
      : [];
    if (
      absenteeOwner.length !==
        methods.getValues("ownershipFilter.absenteeOwner")?.length ||
      !absenteeOwner.every(
        (val, index) =>
          val === methods.getValues("ownershipFilter.absenteeOwner")[index]
      )
    ) {
      methods.setValue("ownershipFilter.absenteeOwner", absenteeOwner);
    }

    if (quickFilters?.includes("CORPORATE_OWNED")) {
      ownerTypeFilters.push("CORPORATE")
    }
    methods.setValue("ownershipFilter.ownerType", ownerTypeFilters);

    methods.setValue("demographicsFilter.inherited", quickFilters?.includes("INHERITED") || false);
    methods.setValue("mortgageInfoFilter.freeClear", quickFilters?.includes("FREE_AND_CLEAR") || false);
    methods.setValue("auctionsPreforeclousreFilter.auction", quickFilters?.includes("ACTIVE_AUCTION") || false);
    methods.setValue("cashBuyersFilter.cashBuyer", quickFilters?.includes("CASH_BUYER") || false);

    const updateAuctionStatus = [
      "NOTICE_OF_DEFAULT",
      "NOTICE_OF_LIS_PENDENS",
      "NOTICE_OF_SALE",
    ].filter(status => quickFilters?.includes(status));

    const arraysEqual = (a: any, b: any) => {
      if (a?.length !== b?.length) return false;
      return a.every((value: any, index: any) => value === b[index]);
    };

    const currentAuctionStatus = methods.getValues("auctionsPreforeclousreFilter.auctionsPreforeclousreStatus");
    if (!arraysEqual(currentAuctionStatus, updateAuctionStatus)) {
      methods.setValue("auctionsPreforeclousreFilter.auctionsPreforeclousreStatus", updateAuctionStatus);
    }
  }, [methods.watch("quickFilters"), methods.getValues, methods.setValue]);
  

  return (
    <div className="quick-filters">
      {QUICK_FILTERS_LIST.map((quickfilter) => (
        <>
          <h2>{quickfilter.categoryName}</h2>
          <div className="dflex gap-8">
          {quickfilter.filters.map((filter) => (
            <span
              onClick={() => handleQuickFilterClick(filter.value)}
              role="link"
              className={`quick-filter ${
                watch("quickFilters")?.includes(filter.value)
                  ? "active-pill"
                  : ``
              }`}
            >
              {filter.label}
            </span>
          ))}
        </div>
        </>
      ))}
    </div>
  );
};
export default QuickFilter;
