import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Tooltip,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AngelDown from "../../../../assets/icons/angleDown";
import Close from "../../../../assets/icons/close";
import CurrencyDollar from "../../../../assets/icons/currencyDoller";
import Percent from "../../../../assets/icons/percent";
import Button from "../../../../atoms/Button";
import DropDown from "../../../../atoms/DropDown";
import Input from "../../../../atoms/Input";
import InfoCard from "../../../../components/infoCard/InfoCard";
import { getProfileInfo } from "../../../../components/profileMenu/selectors";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import { ROLE_PERMISSION_STATUS } from "../../../../constants/constants";
import { ToastType } from "../../../../molecules/toaster/enum";
import { addToast } from "../../../../molecules/toaster/toastSlice";
import { PermissionListEnum } from "../../../../organisms/auth/enum";
import { formatUpdateCurrency } from "../../../../utils/utils";
import Login from "../../../login/Login";
import { addInterestOptions, symbolOptions } from "../../columnsData";
import {
  setFinanceCalculatorDetail,
  setFinanceCalculatorlist,
} from "../../property.reducer";
import {
  fetchFinanceCalculatorAction,
  fetchFinanceCalculatorIdAction,
} from "../../property.sagaAction";
import {
  getFinanceCalculatorDetail,
  getFinanceCalculatorlist,
  getFinanceCalculatorLoading,
} from "../../property.selector";
import {
  postFinanceCalculator,
  updateFinanceCalculator,
} from "../../property.service";
import {
  addValues,
  calculateCumulativeInterest,
  calculateDifference,
  calculateDivision,
  calculateMultiplication,
  calculatePaymentOrDifference,
  calculateSellerFinanceBalanceEnd,
  calculateTotalFinancedAmount,
  conditionalValue,
  downPaymentCalculator,
  estimatedResaleProfit,
  percentageToDecimal,
  subToBalanceAtBalloonEnd,
} from "../utils";
import { DEFAULT_CREATIVE_CALCULATOR_DATA } from "./constants";
import styles from "./CreativeFinance.module.scss";
import { creativeFinanceSchema } from "./validation";

const CreativeFinance = () => {
  const {
    formState: { errors, isValid, isDirty },
    register,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(creativeFinanceSchema),
    defaultValues: DEFAULT_CREATIVE_CALCULATOR_DATA,
  });
  // let navigate = useNavigate();
  const totalFigurePurchasePrice = watch("totalFigurePurchasePrice");
  const downPayment = watch("downPayment");
  const downPaymentSymbol = watch("downPaymentSymbol");
  const closingCosts = watch("closingCosts");
  const closingCostsSymbol = watch("closingCostsSymbol");
  const agentFee = watch("agentFee");
  const agentFeeSymbol = watch("agentFeeSymbol");
  const homeInspection = watch("homeInspection") || "0";
  const Attorney = watch("Attorney") || "0";
  const balloonTerm = watch("balloonTerm") || "0";
  const addInterest = JSON.parse(watch("addInterest") || "true");
  const interestRate = watch("interestRate") || "0";
  const amortization = watch("amortization") || "0";
  const mortgageBalance = watch("mortgageBalance") || "0";
  const mortgagePayment = watch("mortgagePayment") || "0";
  const mortgagInterestRate = watch("mortgagInterestRate") || "0";
  const propertyTaxes = watch("propertyTaxes") || "0";
  const propertyTaxesEscrowedIntoMortgage = JSON.parse(
    watch("propertyTaxesEscrowedIntoMortgage") || "true"
  );
  const InsuranceEscrowedIntoMortgage = JSON.parse(
    watch("InsuranceEscrowedIntoMortgage") || "true"
  );
  const Insurance = watch("Insurance") || 0;
  const servicingCompany = watch("servicingCompany") || "0";
  const Utilities = watch("Utilities") || "0";
  const management = watch("management") || "0";
  const capitalExpenditures = watch("capitalExpenditures");

  const capitalExpendituresSymbol = watch("capitalExpendituresSymbol");
  const Vacany = watch("vacany") || "0";
  const RentalIncome = watch("RentalIncome") || "0";
  const DesiredProfit = watch("DesiredProfit") || "0";
  const EstimatedAppreciationUpToBalloonEnd =
    watch("estimatedAppreciationUpToBalloonEnd") || "0";
  const dispatch = useDispatch();
  const records = useSelector((state) => getFinanceCalculatorlist(state));
  const selectedHistory = useSelector((state) =>
    getFinanceCalculatorDetail(state)
  );
  const calculatorLoading = useSelector((state) =>
    getFinanceCalculatorLoading(state)
  );
  const [selectedHistoryId, setSelectedHistoryId] = useState(0);
  const [selectedHistoryLabel, setSelectedHistoryLabel] = useState("");
  const [saveFinanceModal, setSaveFinanceModal] = useState(false);
  const today = new Date();
  const initialDate = today.toISOString().split("T")[0];
  const [recordName, setRecordName] = useState(initialDate);
  const [loading, setLoading] = useState(false);
  const historyname = useRef("");
  const [recordOption, setRecordOption] = useState<
    { value: number | string; label: any }[]
  >([]);
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const profileData = useSelector((state) => getProfileInfo(state));
  const [email, setEmail] = useState<string>("");
  const shadowBoxRefs = useRef<HTMLDivElement[]>([]);

  //////UpFront costs breakdown
  const calculateDownPayment = downPaymentCalculator(
    downPaymentSymbol,
    totalFigurePurchasePrice,
    downPayment
  );
  const calculateClosingCost = downPaymentCalculator(
    closingCostsSymbol,
    totalFigurePurchasePrice,
    closingCosts
  );
  const calculateAgentFee = downPaymentCalculator(
    agentFeeSymbol,
    totalFigurePurchasePrice,
    agentFee
  );
  const calculateTotalUpfrontCost = addValues(
    calculateDownPayment,
    calculateClosingCost,
    calculateAgentFee,
    homeInspection,
    Attorney
  );

  ////Financing
  const totalFinancedAmount = calculateTotalFinancedAmount(
    totalFigurePurchasePrice,
    calculateDownPayment
  );

  ////subTo Portion
  const calculatesubToBalanceAtBalloonEnd = subToBalanceAtBalloonEnd(
    mortgagInterestRate,
    balloonTerm,
    mortgagePayment,
    mortgageBalance
  );

  //////Firsthalf-Monthly Expenses Breakdown
  const calculatePropertyTaxes = conditionalValue(
    propertyTaxesEscrowedIntoMortgage,
    propertyTaxes
  );
  const calculateInsurance = conditionalValue(
    InsuranceEscrowedIntoMortgage,
    Insurance
  );
  const calculateVacancyPercent = percentageToDecimal(Vacany);
  const calculateVacancy = calculateMultiplication(
    calculateVacancyPercent,
    RentalIncome
  );
  const calculateCapitalExpenditures = downPaymentCalculator(
    capitalExpendituresSymbol,
    capitalExpenditures,
    RentalIncome
  );
  const calculateMonthlyHardExpenses = addValues(
    calculatePropertyTaxes,
    calculateInsurance,
    servicingCompany,
    Utilities,
    management,
    calculateCapitalExpenditures,
    calculateVacancy
  );

  ////seller finance breakdown
  const calculateFinancePortion = calculateDifference(
    totalFinancedAmount,
    mortgageBalance
  );
  const totalInterest = calculateCumulativeInterest(
    addInterest,
    interestRate,
    amortization,
    calculateFinancePortion,
    balloonTerm
  );
  const calculateSellerFinancePayment = calculatePaymentOrDifference(
    addInterest,
    interestRate,
    amortization,
    calculateFinancePortion,
    RentalIncome,
    DesiredProfit,
    calculateMonthlyHardExpenses,
    mortgagePayment
  );
  const calculateSellerFinanceBalanceBalloonEnd =
    calculateSellerFinanceBalanceEnd(
      addInterest,
      interestRate,
      amortization,
      balloonTerm,
      calculateSellerFinancePayment,
      calculateFinancePortion
    );

  //////Secondhalf-Monthly Expenses Breakdown
  const calculateCreativeFinancingPaymentmo = addValues(
    mortgagePayment,
    calculateSellerFinancePayment
  );
  const calculateTotalMonthlyExpenses = addValues(
    calculateMonthlyHardExpenses,
    calculateCreativeFinancingPaymentmo
  );

  //////Deal Stats
  const calculateMonthlyProfit = calculateDifference(
    RentalIncome,
    calculateTotalMonthlyExpenses
  );
  const calculateNoi = calculateMultiplication(calculateMonthlyProfit, 12);
  const divisionResultCashOnCash = calculateDivision(
    calculateNoi,
    calculateTotalUpfrontCost
  );
  const calculateCashOnCash = divisionResultCashOnCash * 100;
  const divisionCapRate = calculateDivision(
    calculateNoi,
    totalFigurePurchasePrice
  );
  const calculateCapRate = divisionCapRate * 100;
  const calculateROITime = calculateDivision(
    calculateTotalUpfrontCost,
    calculateMonthlyProfit
  );

  ///Buy and hold breakdown
  const calculateRentProfitToBalloonEnd = calculateMultiplication(
    calculateNoi,
    balloonTerm
  );
  const CalculateTotalBalanceatBalloonEnd = addValues(
    calculatesubToBalanceAtBalloonEnd,
    calculateSellerFinanceBalanceBalloonEnd
  );
  const calculateEstimatedAppreciationUpToBalloonEndPercent =
    percentageToDecimal(EstimatedAppreciationUpToBalloonEnd);
  const calculateEstimatedAppreciationAmount = calculateMultiplication(
    totalFigurePurchasePrice,
    calculateEstimatedAppreciationUpToBalloonEndPercent
  );
  const calculateEstimatedValueAfterAppreciation = addValues(
    totalFigurePurchasePrice,
    calculateEstimatedAppreciationAmount
  );
  const calculateEstimatedResaleProfit = estimatedResaleProfit(
    calculateEstimatedValueAfterAppreciation,
    CalculateTotalBalanceatBalloonEnd
  );
  const calculateEstimatedTotalDealProfit = addValues(
    calculateRentProfitToBalloonEnd,
    calculateEstimatedResaleProfit
  );

  const handleOnFocus = (e: any) => (e.target.readOnly = true);

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const onSubmit = async () => {
    if (historyname.current === "") {
      handleShowToast("Record name is required.", ToastType.error);
    } else if (Object.keys(profileData).length === 0) {
      setOpenLoginModal(true);
    } else {
      if (selectedHistoryId) {
        handleUpdate(getValues(), selectedHistoryId);
      } else {
        handleSave(getValues());
      }
    }
  };

  const handleSave = async (data: any) => {
    try {
      setLoading(true);
      const payload = {
        name: historyname.current,
        inputData: data,
      };
      const result = await postFinanceCalculator(payload);
      setLoading(false);
      handleShowToast(
        "Calculation history saved successfully!",
        ToastType.success
      );
      dispatch(setFinanceCalculatorlist([...records, result.data]));
      handleEditName();
      handleStartNew();
    } catch (err) {
      setLoading(false);
      handleShowToast("Something went wrong", ToastType.error);
    }
  };

  const handleUpdate = async (data: any, id: any) => {
    try {
      setLoading(true);
      const payload = {
        name: historyname.current,
        inputData: data,
      };
      const result = await updateFinanceCalculator(payload, id);
      setLoading(false);
      handleShowToast(
        "Calculation history updated successfully!",
        ToastType.success
      );

      const updatedRecords = records.map((list: any) =>
        list.id === id ? result?.data : list
      );
      dispatch(setFinanceCalculatorlist(updatedRecords));
      handleEditName();
      handleStartNew();
    } catch (err) {
      setLoading(false);
      handleShowToast("Something went wrong", ToastType.error);
    }
  };

  const handleEditName = () => {
    setSaveFinanceModal(!saveFinanceModal);
  };

  const handleRecordSelect = (value: any) => {
    if (value?.value) {
      setSelectedHistoryLabel(value?.label);
      setSelectedHistoryId(value.value);
      dispatch(fetchFinanceCalculatorIdAction(value.value));
    }
  };

  const handleStartNew = () => {
    reset(DEFAULT_CREATIVE_CALCULATOR_DATA);
    setSelectedHistoryId(0);
    setSelectedHistoryLabel("");
    historyname.current = "";
    dispatch(setFinanceCalculatorDetail({}));
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const accordionId = entry.target.getAttribute("data-accordion-id");
            if (accordionId) {
              const accordionHeader = document.getElementById(accordionId);

              if (
                accordionHeader &&
                accordionHeader.getAttribute("aria-expanded") === "false"
              ) {
                accordionHeader.click();
              }
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    shadowBoxRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      shadowBoxRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  useEffect(() => {
    return () => {
      handleStartNew();
    };
  }, []);

  useEffect(() => {
    if (selectedHistoryLabel === "") {
      const newDefaultValue = `${recordName}(${totalFigurePurchasePrice})`;
      historyname.current = newDefaultValue;
    } else {
      historyname.current = selectedHistoryLabel;
    }
  }, [
    recordName,
    totalFigurePurchasePrice,
    selectedHistoryLabel,
    saveFinanceModal,
  ]);

  useEffect(() => {
    if (
      selectedHistory?.inputData &&
      typeof selectedHistory.inputData === "object"
    ) {
      const currentValues = getValues() || {};
      const updatedValues = { ...selectedHistory.inputData };
      delete updatedValues.record;
      reset({ ...currentValues, ...updatedValues });
    }
  }, [selectedHistory, reset, getValues]);

  useEffect(() => {
    if (Object.keys(records).length > 0) {
      const options = records?.map((list: any) => ({
        value: list?.id,
        label: list?.name,
      }));
      setRecordOption(options);
    } else {
      setRecordOption([{ value: "", label: "No result found" }]);
    }
  }, [records]);

  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      dispatch(fetchFinanceCalculatorAction({}));
      dispatch(setFinanceCalculatorDetail({}));
    }
  }, [profileData]);

  useEffect(() => {
    if (watch("record")) {
      setValue("record", watch("record"));
    }
  }, [records]);

  return (
    <>
      <div className={` ${styles.finance}`}>
        <div>
          <div
            className={` ${styles.finance__records} ${styles.finance__row} dflex`}
          >
            {Object.keys(profileData).length > 0 ? (
              <>
                <div className={` ${styles.finance__colFull}`}>
                  <DropDown
                    options={recordOption}
                    control={control}
                    name="record"
                    label="Calculator History"
                    placeholder={"Select the history"}
                    handleOnChange={handleRecordSelect}
                  ></DropDown>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className={` ${styles.finance__main} dflex`}>
            {calculatorLoading ? (
              <div className="map-loader">
                <div className="dot-pulse"></div>
              </div>
            ) : (
              ""
            )}
            <div className={` ${styles.finance__left}`} id="parentDiv">
              <ShadowBox title="Upfront Costs" noShadow>
                <div
                  className={` ${styles.finance__row} dflex`}
                  ref={(el) => {
                    if (el) shadowBoxRefs.current[0] = el;
                  }}
                  data-accordion-id="panel1-header"
                >
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="currency"
                      label="Total Purchase Price"
                      register={register}
                      name="totalFigurePurchasePrice"
                      placeholder="Enter the total purchase price"
                      errors={errors}
                      prefix={<CurrencyDollar />}
                      control={control}
                    ></Input>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <div className={` ${styles.finance__row} dflex`}>
                      <div className={` ${styles.finance__colInput}`}>
                        <Input
                          type="currency"
                          label="Down Payment (do 15% max)*"
                          register={register}
                          name="downPayment"
                          placeholder="Enter down payment"
                          errors={errors}
                          control={control}
                        ></Input>
                      </div>
                      <div className={` ${styles.finance__colDrop}`}>
                        <DropDown
                          options={symbolOptions}
                          label=""
                          control={control}
                          name="downPaymentSymbol"
                          errors={errors}
                          placeholder="Select value"
                          handleOnFocus={(e: any) => handleOnFocus(e)}
                        ></DropDown>
                      </div>
                    </div>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <div className={` ${styles.finance__row} dflex`}>
                      <div className={` ${styles.finance__colInput}`}>
                        <Input
                          type="currency"
                          label="Closing Costs*"
                          register={register}
                          name="closingCosts"
                          placeholder="Enter closing costs"
                          errors={errors}
                          control={control}
                        ></Input>
                      </div>
                      <div className={` ${styles.finance__colDrop}`}>
                        <DropDown
                          options={symbolOptions}
                          label=""
                          control={control}
                          name="closingCostsSymbol"
                          errors={errors}
                          placeholder="Select value"
                          handleOnFocus={(e: any) => handleOnFocus(e)}
                        ></DropDown>
                      </div>
                    </div>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <div className={` ${styles.finance__row} dflex`}>
                      <div className={` ${styles.finance__colInput}`}>
                        <Input
                          type="currency"
                          label="Agent Fee"
                          register={register}
                          name="agentFee"
                          placeholder="Enter agent fee"
                          control={control}
                          errors={errors}
                        ></Input>
                      </div>
                      <div className={` ${styles.finance__colDrop}`}>
                        <DropDown
                          options={symbolOptions}
                          label=""
                          control={control}
                          name="agentFeeSymbol"
                          errors={errors}
                          placeholder="Select value"
                          handleOnFocus={(e: any) => handleOnFocus(e)}
                        ></DropDown>
                      </div>
                    </div>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="currency"
                      label="Home Inspection"
                      register={register}
                      name="homeInspection"
                      placeholder="Enter home inspection"
                      errors={errors}
                      prefix={<CurrencyDollar />}
                      control={control}
                    ></Input>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="currency"
                      label="Attorney"
                      register={register}
                      name="Attorney"
                      placeholder="Enter attorney"
                      prefix={<CurrencyDollar />}
                      errors={errors}
                      control={control}
                    ></Input>
                  </div>
                </div>
              </ShadowBox>
              <ShadowBox title="Seller Finance" noShadow>
                <div
                  className={` ${styles.finance__row} dflex`}
                  ref={(el) => {
                    if (el) shadowBoxRefs.current[1] = el;
                  }}
                  data-accordion-id="panel2-header"
                >
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="numericDecimal"
                      control={control}
                      label=" Balloon Term (years)"
                      register={register}
                      name="balloonTerm"
                      placeholder="Enter balloon term"
                      errors={errors}
                    ></Input>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <DropDown
                      options={addInterestOptions}
                      label="Add Interest?"
                      control={control}
                      name="addInterest"
                      errors={errors}
                      placeholder="Select value"
                      handleOnFocus={(e: any) => handleOnFocus(e)}
                    ></DropDown>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="numericDecimal"
                      label="Interest Rate"
                      register={register}
                      control={control}
                      name="interestRate"
                      placeholder="Enter interest rate"
                      errors={errors}
                      prefix={<Percent />}
                    ></Input>
                  </div>

                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="currency"
                      control={control}
                      label="Amortization (years)"
                      register={register}
                      name="amortization"
                      placeholder="Enter amortization"
                      errors={errors}
                    ></Input>
                  </div>
                </div>
              </ShadowBox>
              <ShadowBox title="Sub to" noShadow>
                <div
                  className={` ${styles.finance__row} dflex`}
                  ref={(el) => {
                    if (el) shadowBoxRefs.current[2] = el;
                  }}
                  data-accordion-id="panel3-header"
                >
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="currency"
                      label="Mortgage Balance"
                      register={register}
                      name="mortgageBalance"
                      placeholder="Enter the total figure purchase price"
                      errors={errors}
                      prefix={<CurrencyDollar />}
                      control={control}
                    ></Input>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="currency"
                      label="Mortgage Payment / mo"
                      register={register}
                      name="mortgagePayment"
                      placeholder="Enter mortgage payment"
                      errors={errors}
                      prefix={<CurrencyDollar />}
                      control={control}
                    ></Input>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="numericDecimal"
                      label="Interest Rate"
                      control={control}
                      register={register}
                      name="mortgagInterestRate"
                      placeholder="Enter interest rate"
                      errors={errors}
                      prefix={<Percent />}
                    ></Input>
                  </div>
                </div>
              </ShadowBox>
              <ShadowBox title="Cash Flow" noShadow>
                <div
                  className={` ${styles.finance__row} dflex`}
                  ref={(el) => {
                    if (el) shadowBoxRefs.current[3] = el;
                  }}
                  data-accordion-id="panel4-header"
                >
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="currency"
                      label="Desired Profit / mo"
                      register={register}
                      name="DesiredProfit"
                      placeholder="Enter desired profit / mo" 
                      errors={errors}
                      prefix={<CurrencyDollar />}
                      control={control}
                      disabled={watch("addInterest")==="true"}
                         tooltipText={watch("addInterest")==="true" ?"Disable interest under seller finance to enable this field" :""}
                    ></Input>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="currency"
                      label="Rental Income / mo"
                      register={register}
                      name="RentalIncome"
                      placeholder="Enter rental income / mo"
                      errors={errors}
                      prefix={<CurrencyDollar />}
                      control={control}
                    ></Input>
                  </div>
                </div>
              </ShadowBox>
              <ShadowBox title="Monthly Expenses" noShadow>
                <div
                  className={` ${styles.finance__row} dflex`}
                  ref={(el) => {
                    if (el) shadowBoxRefs.current[4] = el;
                  }}
                  data-accordion-id="panel5-header"
                >
                  <div className={` ${styles.finance__col3}`}>
                    <DropDown
                      options={addInterestOptions}
                      label="Property taxes escrowed into mortgage"
                      control={control}
                      name="propertyTaxesEscrowedIntoMortgage"
                      errors={errors}
                      placeholder="Select value"
                      handleOnFocus={(e: any) => handleOnFocus(e)}
                    ></DropDown>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="currency"
                      label="Property Taxes"
                      register={register}
                      name="propertyTaxes"
                      placeholder="Enter property taxes"
                      errors={errors}
                      control={control}
                      prefix={<CurrencyDollar />}
                      disabled={watch("propertyTaxesEscrowedIntoMortgage")==="true"}
                    ></Input>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <DropDown
                      options={addInterestOptions}
                      label="Insurance escrowed into mortgage"
                      control={control}
                      name="InsuranceEscrowedIntoMortgage"
                      errors={errors}
                      placeholder="Select value"
                      handleOnFocus={(e: any) => handleOnFocus(e)}
                    ></DropDown>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="numericDecimal"
                      label="Insurance"
                      control={control}
                      register={register}
                      name="Insurance"
                      placeholder="Enter insurance"
                      prefix={<CurrencyDollar />}
                      errors={errors}
                    ></Input>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="currency"
                      label="Servicing Company"
                      register={register}
                      name="servicingCompany"
                      placeholder="Enter servicing company"
                      errors={errors}
                      prefix={<CurrencyDollar />}
                      control={control}
                    ></Input>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="currency"
                      label="Utilities"
                      register={register}
                      name="Utilities"
                      placeholder="Enter utilities"
                      errors={errors}
                      prefix={<CurrencyDollar />}
                      control={control}
                    ></Input>
                  </div>

                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="currency"
                      label="Management"
                      register={register}
                      name="management"
                      placeholder="Enter management"
                      errors={errors}
                      prefix={<CurrencyDollar />}
                      control={control}
                    ></Input>
                  </div>

                  <div className={` ${styles.finance__col3}`}>
                    <div className={` ${styles.finance__row} dflex`}>
                      <div className={` ${styles.finance__colInput}`}>
                        <Input
                          type="currency"
                          label="Capital Expenditures (CapX)"
                          register={register}
                          name="capitalExpenditures"
                          placeholder="Enter capital expenditures"
                          errors={errors}
                          control={control}
                        ></Input>
                      </div>
                      <div className={` ${styles.finance__colDrop}`}>
                        <DropDown
                          options={symbolOptions}
                          label=""
                          control={control}
                          name="capitalExpendituresSymbol"
                          errors={errors}
                          placeholder="Select value"
                          handleOnFocus={(e: any) => handleOnFocus(e)}
                        ></DropDown>
                      </div>
                    </div>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="numericDecimal"
                      control={control}
                      label="Vacancy"
                      register={register}
                      name="vacany"
                      placeholder="Enter vacancy"
                      errors={errors}
                      prefix={<Percent />}
                    ></Input>
                  </div>
                </div>
              </ShadowBox>
              <ShadowBox title="Buy and hold" noShadow>
                <div
                  className={` ${styles.finance__row} dflex`}
                  ref={(el) => {
                    if (el) shadowBoxRefs.current[5] = el;
                  }}
                  data-accordion-id="panel7-header"
                >
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="numericDecimal"
                      control={control}
                      label="Estimated Appreciation up to Balloon End"
                      register={register}
                      name="estimatedAppreciationUpToBalloonEnd"
                      placeholder="Enter estimated appreciation up to balloon end"
                      errors={errors}
                      prefix={<Percent />}
                    ></Input>
                  </div>
                  <div className={` ${styles.finance__col3}`}>
                    <Input
                      type="numericDecimal"
                      control={control}
                      label="How many units"
                      register={register}
                      name="howManyUnits"
                      placeholder="Enter units"
                      errors={errors}
                      tooltipText="Assuming you make at least $300 per unit"
                    ></Input>
                  </div>
                </div>
              </ShadowBox>
            </div>
            <div className={` ${styles.finance__right}`}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<AngelDown />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Upfront Costs Breakdown
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`dflex ${styles.row}`}>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Down Payment"
                        description={`$${formatUpdateCurrency(
                          calculateDownPayment
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Closing Costs"
                        description={`$${formatUpdateCurrency(
                          calculateClosingCost
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Agent Fee"
                        description={`$${formatUpdateCurrency(
                          calculateAgentFee
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Home Inspection"
                        description={`$${
                          formatUpdateCurrency(homeInspection) || 0
                        }`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Attorney"
                        description={`$${formatUpdateCurrency(Attorney) || 0}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Total Upfront Costs"
                        description={`$${formatUpdateCurrency(
                          calculateTotalUpfrontCost
                        )}`}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<AngelDown />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  Seller Finance Breakdown
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`dflex ${styles.row}`}>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Seller Finance Portion"
                        description={`$${formatUpdateCurrency(
                          calculateFinancePortion.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Total Interest"
                        description={`$${formatUpdateCurrency(
                          totalInterest.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Seller Finance Balance at Balloon End"
                        description={`$${formatUpdateCurrency(
                          calculateSellerFinanceBalanceBalloonEnd.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Seller Finance Payment / mo"
                        description={`$${formatUpdateCurrency(
                          calculateSellerFinancePayment.toString()
                        )}`}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<AngelDown />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  SubTo Breakdown
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`dflex ${styles.row}`}>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="SubTo Portion"
                        description={`$${
                          formatUpdateCurrency(mortgageBalance) || 0
                        }`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="SubTo Balance at Balloon End"
                        description={`$${formatUpdateCurrency(
                          calculatesubToBalanceAtBalloonEnd.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="SubTo Payment / mo"
                        description={`$${
                          formatUpdateCurrency(mortgagePayment) || 0
                        }`}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<AngelDown />}
                  aria-controls="panel4-content"
                  id="panel4-header"
                >
                  Financing
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`dflex ${styles.row}`}>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Total Financed Amount"
                        description={`$${formatUpdateCurrency(
                          totalFinancedAmount.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Total Balance at Balloon End"
                        description={`$${formatUpdateCurrency(
                          CalculateTotalBalanceatBalloonEnd.toString()
                        )}`}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<AngelDown />}
                  aria-controls="panel5-content"
                  id="panel5-header"
                >
                  Monthly Expenses Breakdown
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`dflex ${styles.row}`}>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Property Taxes (if 0, escrowed into mortgage)"
                        description={`$${formatUpdateCurrency(
                          calculatePropertyTaxes.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Insurance (if 0, escrowed into mortgage)"
                        description={`$${formatUpdateCurrency(
                          calculateInsurance.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Servicing Company"
                        description={`$${
                          formatUpdateCurrency(servicingCompany) || 0
                        }`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Utilities"
                        description={`$${formatUpdateCurrency(Utilities) || 0}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Management"
                        description={`$${
                          formatUpdateCurrency(management) || 0
                        }`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Capital Expenditures (CapX)"
                        description={`$${formatUpdateCurrency(
                          calculateCapitalExpenditures.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Vacancy"
                        description={`$${formatUpdateCurrency(
                          calculateVacancy
                        )}`}
                      />
                    </div>

                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Monthly Hard Expenses (before creative financing)"
                        description={`$${formatUpdateCurrency(
                          calculateMonthlyHardExpenses?.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Creative Financing Payment / mo"
                        description={`$${formatUpdateCurrency(
                          calculateCreativeFinancingPaymentmo.toString()
                        )}`}
                      />
                    </div>

                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Total Monthly Expenses"
                        description={`$${formatUpdateCurrency(
                          calculateTotalMonthlyExpenses.toString()
                        )}`}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<AngelDown />}
                  aria-controls="panel6-content"
                  id="panel6-header"
                >
                  Deal Stats
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`dflex ${styles.row}`}>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Monthly Profit"
                        description={`$${formatUpdateCurrency(
                          calculateMonthlyProfit.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="NOI"
                        description={`$${formatUpdateCurrency(
                          calculateNoi.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Cash on Cash (good if 30%)"
                        description={`${
                          calculateCashOnCash.toFixed(2)
                        }`}
                        descriptionTooltip={`${formatUpdateCurrency(
                          calculateCashOnCash.toFixed(2)
                        )}%`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Cap Rate"
                        description={`${formatUpdateCurrency(
                          calculateCapRate.toFixed(2)
                        )}%`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="ROI Time (months)"
                        description={`${formatUpdateCurrency(
                          calculateROITime.toFixed(2)
                        )}`}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<AngelDown />}
                  aria-controls="panel7-content"
                  id="panel7-header"
                >
                  Buy & Hold Breakdown
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`dflex ${styles.row}`}>
                    {/* <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Rent Profit up to Balloon End"
                        description={`$${formatUpdateCurrency(
                          calculateRentProfitToBalloonEnd.toString()
                        )}`}
                      />
                    </div> */}
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Estimated Appreciation Amount"
                        description={`$${formatUpdateCurrency(
                          calculateEstimatedAppreciationAmount.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Estimated Value After Appreciation"
                        description={`$${formatUpdateCurrency(
                          calculateEstimatedValueAfterAppreciation.toString()
                        )}`}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<AngelDown />}
                  aria-controls="panel8-content"
                  id="panel8-header"
                >
                  Deal Profit
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`dflex ${styles.row}`}>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Rental Profit"
                        description={`$${formatUpdateCurrency(
                          calculateRentProfitToBalloonEnd.toString()
                        )}`}
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Estimated Resale Profit"
                        description={`$${formatUpdateCurrency(
                          calculateEstimatedResaleProfit.toString()
                        )}`}
                        icon="Assuming 7% cost of sale"
                      />
                    </div>
                    <div className={`${styles.row__col}`}>
                      <InfoCard
                        title="Estimated Total Deal Profit"
                        description={`$${formatUpdateCurrency(
                          calculateEstimatedTotalDealProfit.toString()
                        )}`}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className={` ${styles.finance__Save} flex justifyEnd`}>
            <div className={` dflex ${styles.finance__SaveButton}`}>
              <Button
                className="primary"
                label={"Start new"}
                action={handleStartNew}
              ></Button>
              <Tooltip
                title={
                  profileData?.userRoleName ===
                  PermissionListEnum.ORGANISATION_VIEWER
                    ? ROLE_PERMISSION_STATUS.NoAccess
                    : ""
                }
              >
                <div>
                  {profileData?.userRoleName ===
                  PermissionListEnum.ORGANISATION_VIEWER ? (
                    <Button
                      label={selectedHistoryId ? "Update" : "Save"}
                      className="primary full animated "
                      disabled={
                        profileData?.userRoleName ===
                        PermissionListEnum.ORGANISATION_VIEWER
                      }
                    ></Button>
                  ) : (
                    <Button
                      label={selectedHistoryId ? "Update" : "Save"}
                      className="primary full animated "
                      action={handleEditName}
                      disabled={!isValid}
                    ></Button>
                  )}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      {saveFinanceModal ? (
        <Dialog
          className="stepsDialog editModal editModalLarge"
          onClose={handleEditName}
          open={saveFinanceModal}
        >
          <div className="dialogWrapper">
            <span role="link" className="closeDialog" onClick={handleEditName}>
              <Close />
            </span>
            <div className="dialogWrapper__header">
              <h2>Record</h2>
            </div>
            <label>Record name*</label>
            <input
              placeholder="Enter record name"
              type="text"
              defaultValue={historyname?.current}
              disabled={loading}
              onChange={(e: any) =>
                (historyname.current = e.target.value.trim())
              }
            />
            <div className={` editModal__button`}>
              <Button
                className="primary full animated"
                label={selectedHistoryId ? "Update" : "Save"}
                action={handleSubmit(onSubmit)}
                clicked={loading}
                disabled={loading}
              />
            </div>
          </div>
        </Dialog>
      ) : (
        ""
      )}

      <Login
        open={openLoginModal}
        setOpen={setOpenLoginModal}
        setEmail={setEmail}
        email={email}
        calSubmit={onSubmit}
        saveCalDetail
      ></Login>
    </>
  );
};

export default CreativeFinance;
