export enum CardElementType {
  CardCVV = "cardCvc",
  CardExpiry = "cardExpiry",
  CardNumber = "cardNumber",
}

export enum ProductPriceIntervalType {
  YEAR = "year",
  MONTH = "month",
  ONETIME = "one_time"
}

export enum CardBrands {
  Amex = "amex",
  Discover = "discover",
  Mastercard = "mastercard",
  Visa = "visa",
  JCB = "jcb",
  Unionpay = "unionpay",
  EftposAustralia = "eftposaustralia",
}
export enum ProductType {
  CREDIT_ACCESS = "credit_access",
  GENERAL_ACCESS = "general_access",
}