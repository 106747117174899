import * as yup from "yup";

export const advancedFilterFormSchema = yup.object({
  propertyCharacteristicsFilter: yup.object({
    minSquareFeet: yup
      .string()
      .test("min-max-required", "Square Feet min is required", function (value) {
        if (this.parent.maxSquareFeet && !value) {
          return false;
        }
        return true;
      }),
    maxSquareFeet: yup
      .string()
      .test("min-max-required", "Square Feet max is required", function (value) {
        if (this.parent.minSquareFeet && !value) {
          return false;
        }
        return true;
      }),

    minLotSize: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Lot Size cannot be less than 1")
      .test(
        "min",
        "Lot Size min must be less than or equal to 10000",
        function (value) {
          if (value && parseInt(value, 10) > 10000) {
            return false;
          }
          return true;
        }
      )
      .test("min-max-required", "Lot Size min is required", function (value) {
        if (this.parent.maxLotSize && !value) {
          return false;
        }
        return true;
      }),

    maxLotSize: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Lot Size cannot be less than 1")
      .test(
        "min-max",
        "Lot Size max cannot be less than Lot Size min",
        function (value) {
          return minMaxEqualValidation(this.parent.minLotSize, value);
        }
      )
      .test(
        "max",
        "Lot Size max must be less than or equal to 10000",
        function (value) {
          if (value && parseInt(value, 10) > 10000) {
            return false;
          }
          return true;
        }
      )
      .test("min-max-required", "Lot Size max is required", function (value) {
        if (this.parent.minLotSize && !value) {
          return false;
        }
        return true;
      }),

    yearBuiltMin: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Year built cannot be less than 1")
      .test(
        "min",
        "Year built min must be less than or equal to 10000",
        function (value) {
          if (value && parseInt(value, 10) > 10000) {
            return false;
          }
          return true;
        }
      )
      .test("min-max-required", "Year built min is required", function (value) {
        if (this.parent.yearBuiltMax && !value) {
          return false;
        }
        return true;
      }),

    yearBuiltMax: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Year built cannot be less than 1")
      .test(
        "min-max",
        "Year built max cannot be less than year built min",
        function (value) {
          return minMaxEqualValidation(this.parent.yearBuiltMin, value);
        }
      )
      .test(
        "max",
        "Year built max must be less than or equal to 10000",
        function (value) {
          if (value && parseInt(value, 10) > 10000) {
            return false;
          }
          return true;
        }
      )
      .test("min-max-required", "Year built max is required", function (value) {
        if (this.parent.yearBuiltMin && !value) {
          return false;
        }
        return true;
      }),

    storiesMin: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Stories cannot be less than 1")
      .test("min-max-required", "Stories min is required", function (value) {
        if (this.parent.storiesMax && !value) {
          return false;
        }
        return true;
      }),
    storiesMax: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Stories cannot be less than 1")
      .test(
        "min-max",
        "Stories max cannot be less than stories min",
        function (value) {
          return minMaxEqualValidation(this.parent.storiesMin, value);
        }
      )
      .test("min-max-required", "Stories max is required", function (value) {
        if (this.parent.storiesMin && !value) {
          return false;
        }
        return true;
      }),

    minBedrooms: yup
      .string()
      .nullable()
      .nullable()
      .trim()
      .matches(/^$|^[0-9]*$/, "Bedrooms cannot be less than 0")
      .test(
        "max",
        "Bedrooms min must be less than or equal to 99",
        function (value) {
          if (value && parseInt(value, 10) > 99) {
            return false;
          }
          return true;
        }
      )
      .test("min-max-required", "Bedrooms min is required", function (value) {
        if (this.parent.maxBedrooms && !value) {
          return false;
        }
        return true;
      }),
    maxBedrooms: yup
      .string()
      .nullable()
      .nullable()
      .trim()
      .matches(/^$|^[1-9]\d*$/, "Bedrooms cannot be less than 1")
      .test(
        "min-max",
        "Bedrooms max cannot be less than Bedrooms min",
        function (value) {
          return minMaxEqualValidation(this.parent.minBedrooms, value);
        }
      )
      .test(
        "max",
        "Bedrooms max must be less than or equal to 99",
        function (value) {
          if (value && parseInt(value, 10) > 99) {
            return false;
          }
          return true;
        }
      )
      .test("min-max-required", "Bedrooms max is required", function (value) {
        if (this.parent.minBedrooms && !value) {
          return false;
        }
        return true;
      }),

    minBathrooms: yup
      .string()
      .nullable()
      .trim()
      .matches(/^$|^[0-9]*$/, "Bathrooms cannot be less than 0")
      .test(
        "max",
        "Bathrooms min must be less than or equal to 99",
        function (value) {
          if (value && parseInt(value, 10) > 99) {
            return false;
          }
          return true;
        }
      )
      .test("min-max-required", "Bathrooms min is required", function (value) {
        if (this.parent.maxBathrooms && !value) {
          return false;
        }
        return true;
      }),
    maxBathrooms: yup
      .string()
      .nullable()
      .trim()
      .matches(/^$|^[1-9]\d*$/, "Bathrooms cannot be less than 1")
      .test(
        "min-max",
        "Bathrooms max cannot be less than Bathrooms min",
        function (value) {
          return minMaxEqualValidation(this.parent.minBathrooms, value);
        }
      )
      .test(
        "max",
        "Bathrooms max must be less than or equal to 99",
        function (value) {
          if (value && parseInt(value, 10) > 99) {
            return false;
          }
          return true;
        }
      )
      .test("min-max-required", "Bathrooms max is required", function (value) {
        if (this.parent.minBathrooms && !value) {
          return false;
        }
        return true;
      }),
  }),

  auctionsPreforeclousreFilter: yup.object({}),

  ownershipFilter: yup.object({
    yearsOwnedMin: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Years Owned cannot be less than 1")
      .test(
        "min-max-required",
        "Years Owned min is required",
        function (value) {
          if (this.parent.yearsOwnedMax && !value) {
            return false;
          }
          return true;
        }
      ),
    yearsOwnedMax: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Years Owned cannot be less than 1")
      .test(
        "min-max",
        "Years Owned max cannot be less than Years Owned min",
        function (value) {
          return minMaxEqualValidation(this.parent.yearsOwnedMin, value);
        }
      )
      .test(
        "min-max-required",
        "Years Owned max is required",
        function (value) {
          if (this.parent.yearsOwnedMin && !value) {
            return false;
          }
          return true;
        }
      ),
    lastSalePriceMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Last Sale Price cannot be less than 0")
      .test(
        "min-max-required",
        "Last Sale Price min is required",
        function (value) {
          if (this.parent.lastSalePriceMax && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value: any) => (value ? value : 0)),
    lastSalePriceMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Last Sale Price cannot be less than 0")
      // .transform((value) => (value ? value : 0))
      .test(
        "min-max",
        "Last Sale price max cannot be less than last sale price min",
        function (value) {
          return minMaxEqualValidation(this.parent.lastSalePriceMin, value);
        }
      )
      .test(
        "min-max-required",
        "Last Sale Price max is required",
        function (value) {
          if (this.parent.lastSalePriceMin && !value) {
            return false;
          }
          return true;
        }
      ),
  }),

  taxLienFilter: yup.object({
    taxDelinquentYearMin: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Tax Delinquent Year cannot be less than 1")
      .test(
        "min-max-required",
        "Tax Delinquent year min is required",
        function (value) {
          if (this.parent.taxDelinquentYearMax && !value) {
            return false;
          }
          return true;
        }
      ),
    taxDelinquentYearMax: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Tax Delinquent Year cannot be less than 1")
      .test(
        "min-max",
        "Tax delinquent year max cannot be less than tax delinquent year  min",
        function (value) {
          return minMaxEqualValidation(this.parent.taxDelinquentYearMin, value);
        }
      )
      .test(
        "min-max-required",
        "Tax Delinquent year max is required",
        function (value) {
          if (this.parent.taxDelinquentYearMin && !value) {
            return false;
          }
          return true;
        }
      ),
  }),

  valuationEquityFilter: yup.object({
    equityPercentMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Equity Percent cannot be less than 0")
      .test(
        "min-max-required",
        "Equity Percent min is required",
        function (value) {
          if (this.parent.equityPercentMax && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    equityPercentMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Equity Percent cannot be less than 0")
      .test(
        "min-max",
        "Equity percent max cannot be less than equity percent min",
        function (value) {
          return minMaxEqualValidation(this.parent.equityPercentMin, value);
        }
      )
      .test(
        "min-max-required",
        "Equity Percent max is required",
        function (value) {
          if (this.parent.equityPercentMin && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    estimatedEquityMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Estimated Equity cannot be less than 0")
      .test(
        "min-max-required",
        "Estimated Equity min is required",
        function (value) {
          if (this.parent.estimatedEquityMax && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? parseInt(value) : 0)),
    estimatedEquityMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Estimated Equity cannot be less than 0")
      .test(
        "min-max",
        "Estimated equity max cannot be less than estimated equity min",
        function (value) {
          return minMaxEqualValidation(this.parent.estimatedEquityMin, value);
        }
      )
      .test(
        "min-max-required",
        "Estimated Equity min is required",
        function (value) {
          if (this.parent.estimatedEquityMin && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? parseInt(value) : 0)),
    estimatedValueMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Estimated Value cannot be less than 0")
      .test(
        "min-max-required",
        "Estimated Value min is required",
        function (value) {
          if (this.parent.estimatedValueMax && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    estimatedValueMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Estimated Value cannot be less than 0")
      .test(
        "min-max",
        "Estimated value max cannot be less than estimated value min",
        function (value) {
          return minMaxEqualValidation(this.parent.estimatedValueMin, value);
        }
      )
      .test(
        "min-max-required",
        "Estimated Value max is required",
        function (value) {
          if (this.parent.estimatedValueMin && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    assessedValueMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Assessed Value cannot be less than 0")
      .test(
        "min-max-required",
        "Assessed Value min is required",
        function (value) {
          if (this.parent.assessedValueMax && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    assessedValueMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Assessed Value cannot be less than 0")
      .test(
        "min-max",
        "Assessed value max cannot be less than assessed value min",
        function (value) {
          return minMaxEqualValidation(this.parent.assessedValueMin, value);
        }
      )
      .test(
        "min-max-required",
        "Assessed Value max is required",
        function (value) {
          if (this.parent.assessedValueMin && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    assessedLandValueMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Assessed Land Value cannot be less than 0")
      .test(
        "min-max-required",
        "Assessed Land Value min is required",
        function (value) {
          if (this.parent.assessedLandValueMax && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    assessedLandValueMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Assessed Land Value cannot be less than 0")
      .test(
        "min-max",
        "Assessed land value max cannot be less than assessed land value min",
        function (value) {
          return minMaxEqualValidation(this.parent.assessedLandValueMin, value);
        }
      )
      .test(
        "min-max-required",
        "Assessed Land Value max is required",
        function (value) {
          if (this.parent.assessedLandValueMin && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    assessedImprovementValueMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Assessed Improvement Value cannot be less than 0")
      .test(
        "min-max-required",
        "Assessed improvement value min is required",
        function (value) {
          if (this.parent.assessedImprovementValueMax && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    assessedImprovementValueMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Assessed Improvement Value cannot be less than 0")
      .test(
        "min-max",
        "Assessed improvement value max cannot be less than assessed improvement value min",
        function (value) {
          return minMaxEqualValidation(
            this.parent.assessedImprovementValueMin,
            value
          );
        }
      )
      .test(
        "min-max-required",
        "Assessed improvement value max is required",
        function (value) {
          if (this.parent.assessedImprovementValueMin && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
  }),

  mortgageInfoFilter: yup.object({
    mortgageMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Mortgage cannot be less than 0")
      .test("min-max-required", "Mortgage min is required", function (value) {
        if (this.parent.mortgageMax && !value) {
          return false;
        }
        return true;
      }),
    // .transform((value) => (value ? value : 0)),
    mortgageMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Mortgage cannot be less than 0")
      .test(
        "min-max",
        "Mortgage max cannot be less than mortgage min",
        function (value) {
          return minMaxEqualValidation(this.parent.mortgageMin, value);
        }
      )
      .test("min-max-required", "Mortgage max is required", function (value) {
        if (this.parent.mortgageMin && !value) {
          return false;
        }
        return true;
      }),
    // .transform((value) => (value ? value : 0)),
  }),

  cashBuyersFilter: yup.object({
    propertiesOwnedMin: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Properties Owned cannot be less than 1")
      .test(
        "min-max-required",
        "Properties Owned min is required",
        function (value) {
          if (this.parent.propertiesOwnedMax && !value) {
            return false;
          }
          return true;
        }
      ),
    propertiesOwnedMax: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Properties Owned cannot be less than 1")
      .test(
        "min-max",
        "Properties owned max cannot be less than properties Owned min",
        function (value) {
          return minMaxEqualValidation(this.parent.propertiesOwnedMin, value);
        }
      )
      .test(
        "min-max-required",
        "Properties Owned max is required",
        function (value) {
          if (this.parent.propertiesOwnedMin && !value) {
            return false;
          }
          return true;
        }
      ),
    portfolioValueMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Portfolio Value cannot be less than 0")
      .test(
        "min-max-required",
        "Portfolio Value max is required",
        function (value) {
          if (this.parent.portfolioValueMax && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),

    portfolioValueMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Portfolio Value cannot be less than 0")
      .test(
        "min-max",
        "Portfolio value max cannot be less than portfolio value min",
        function (value) {
          return minMaxEqualValidation(this.parent.portfolioValueMin, value);
        }
      )
      .test(
        "min-max-required",
        "Portfolio value max is required",
        function (value) {
          if (this.parent.portfolioValueMin && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    portfolioEquityMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Portfolio Equity cannot be less than 0")
      .test(
        "min-max-required",
        "Portfolio Equity min is required",
        function (value) {
          if (this.parent.portfolioEquityMax && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    portfolioEquityMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Portfolio Equity cannot be less than 0")
      .test(
        "min-max",
        "Portfolio equity max cannot be less than portfolio equity min",
        function (value) {
          return minMaxEqualValidation(this.parent.portfolioEquityMin, value);
        }
      )
      .test(
        "min-max-required",
        "Portfolio Equity max is required",
        function (value) {
          if (this.parent.portfolioEquityMin && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    portfolioMortgageBalanceMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Portfolio Mortgage Balance cannot be less than 0")
      .test(
        "min-max-required",
        "Portfolio Mortgage Balance min is required",
        function (value) {
          if (this.parent.portfolioMortgageBalanceMax && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    portfolioMortgageBalanceMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Portfolio Mortgage Balance cannot be less than 0")
      .test(
        "min-max",
        "Portfolio mortgage balance max cannot be less than portfolio mortgage balance min",
        function (value) {
          return minMaxEqualValidation(
            this.parent.portfolioMortgageBalanceMin,
            value
          );
        }
      )
      .test(
        "min-max-required",
        "Portfolio mortgage balance max is required",
        function (value) {
          if (this.parent.portfolioMortgageBalanceMin && !value) {
            return false;
          }
          return true;
        }
      ),
  }),
  demographicsFilter: yup.object({
    ownerAgeMin: yup
      .string() .nullable()
      .trim()
      .matches(/^$|^[1-9]\d*$/, "Owner age cannot be less than 1")
      .test(
        "max",
        "Owner min age must be less than or equal to 100",
        function (value) {
          if (value && parseInt(value, 10) > 100) {
            return false;
          }
          return true;
        }
      )
      .test("min-max-required", "Owner min age is required", function (value) {
        if (this.parent.ownerAgeMax && !value) {
          return false;
        }
        return true;
      }),
    ownerAgeMax: yup
      .string().nullable()
      .matches(/^$|^[1-9]\d*$/, "Owner age cannot be less than 1")
      .test(
        "min-max",
        "Owner max age cannot be less than Owner min age",
        function (value) {
          return minMaxEqualValidation(this.parent.ownerAgeMin, value);
        }
      ).test(
        "max",
        "Owner max age must be less than or equal to 100",
        function (value) {
          if (value && parseInt(value, 10) > 100) {
            return false;
          }
          return true;
        }
      )
      .test("min-max-required", "Owner max age is required", function (value) {
        if (this.parent.ownerAgeMin && !value) {
          return false;
        }
        return true;
      }),
    medianIncomeMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Median Income cannot be less than 0")
      .test(
        "min-max-required",
        "Median Income min is required",
        function (value) {
          if (this.parent.medianIncomeMax && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
    medianIncomeMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === "string"
          ? originalValue.replace(/,/g, "")
          : originalValue;
      })
      .nullable()
      .min(0, "Median Income cannot be less than 0")
      .test(
        "min-max",
        "Median income max cannot be less than portfolio median income min",
        function (value) {
          return minMaxEqualValidation(this.parent.medianIncomeMin, value);
        }
      )
      .test(
        "min-max-required",
        "Median Income max is required",
        function (value) {
          if (this.parent.medianIncomeMin && !value) {
            return false;
          }
          return true;
        }
      ),
    // .transform((value) => (value ? value : 0)),
  }),
});

const minMaxEqualValidation = (
  min: string | number | undefined | null,
  max: string | number | undefined | null
) => {
  if (min && max) {
    return +max >= +min;
  } else {
    return true;
  }
};
