import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../atoms/Loader";
import { fetchLeadListDetail } from "../../organisms/listsTable/sagaActions";
import { getLeadsListDetail, getListDetail, getListDetailLoading } from "../../organisms/listsTable/selectors";
import PropertyCard from "../../organisms/propertySearchFilter/PropertyCard";
import styles from "./ListDetail.module.scss";
import CustomModal from "../../components/customModal/CustomModal";
import PropertyDetails from "../property/PropertyDetails";
import { PropertyDetailsParentIdType } from "../property/property.enum";
import CustomCarousel from "../../components/customSlider/CustomCarousel";
import ArrowLeft from "../../assets/icons/arrowLeft";
import {  ArrowRightIcon } from "@mui/x-date-pickers";
import { setSelectedLeadId } from "../../organisms/leadsTable/reducer";

const ListDetail = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listDetailRes = useSelector((state) => getLeadsListDetail(state));
  const listDetail = useSelector((state) => getListDetail(state));
  const listDetailLoading = useSelector((state) =>getListDetailLoading(state));
  const [propertyId, setPropertyId] = useState("");
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<any>(null);
  const [propertyIndex,setPropertyIndex]=useState(-1)
  const [filter, setFilter] = useState<{
    skip: number;
    count: number;
  }>({
    skip: 1,
    count: 11
  });
  
  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, skip: prev?.skip + 1 }));
  };

  const handlePropertyClick = (property: any) => {
    setSelectedProperty(property);
    setPropertyId( property?.leadId);
    dispatch(setSelectedLeadId(property?.leadId));
  };

  const handlePropertyModal = () => {
    setShowPropertyModal(false);
    setPropertyId("");
  };

  const handleNextProperty=()=>{
const currentIndex = listDetailRes.findIndex((item:any) => item.leadId === propertyId); 
  if (currentIndex < listDetailRes.length - 1) {
    setPropertyId(listDetailRes[currentIndex + 1].leadId); 
  } 
  }

  const handlePrevProperty=()=>{
    const currentIndex = listDetailRes.findIndex((item:any) => item.leadId === propertyId);
    if (currentIndex > 0) {
      setPropertyId(listDetailRes[currentIndex - 1]?.leadId);
    } 
  }

  useEffect(() => {
    if (!param.id) return;
      const payload={id:param.id,filter}
    dispatch(fetchLeadListDetail(payload))
  }, [dispatch, param.id,filter]);

  useEffect(() => {
    if (propertyId) {
      setShowPropertyModal(true);
    }
  }, [propertyId]);

  useEffect(() => {
    if (!showPropertyModal) {
      setPropertyId("");
    }
  }, [showPropertyModal])

  useEffect(() => {
    if (Object.keys(listDetailRes).length > 0) {
      const index = listDetailRes.findIndex((item:any) => item?.leadId === propertyId);
      setPropertyIndex(index)
      if (index === 9 && listDetailRes?.length < listDetail?.totalCount) {
        handleNextPage()
      } 
    }
  }, [propertyId]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedLeadId(""));
    };
  }, []);
  
  return ( 
    <>
    
   {listDetailLoading ? <div className={` ${styles.listDetailLoader}`}>
                <Loader></Loader>
              </div> : <div className={` ${styles.listDetail}`}>
        <div className={` ${styles.listDetail__header}`}>
        <ul className="flex">
            <li>
            <span
                role="link"
                className={` ${styles.propertyHeader__leftBack} flex alignCenter`}
                onClick={() => navigate(`/lists`) }
              >
                <ArrowLeft />
                Lists
              </span> 
              </li>
              <li><ArrowRightIcon/></li>
              <li>{listDetail?.name}</li>
           
        </ul>
    
        {/* <h2>{listDetail?.name}</h2> */}
     
        </div>
        <div>
          <InfiniteScroll
            dataLength={listDetailRes?.length}
            next={()=>handleNextPage()}
            hasMore={listDetailRes?.length < listDetail?.totalCount}
            // scrollableTarget="scrollableDiv1"
            loader={
              <div className="table-loader w-full">
                <Loader></Loader>
              </div>
            }
          >
            <div className={` ${styles.listDetail__list} dflex`}>
            {listDetailRes?.map((property: any, index: number) => {
              return (
                <div className={` ${styles.listDetail__card} dflex`}>
                  <PropertyCard
                    property={property}
                    handleMouseHover={() => {}}
                    handleMouseLeave={() => {}}
                    selectedProperty={selectedProperty}
                    handlePropertyClick={handlePropertyClick}
                    propertiesInView={[]}
                    status={property?.leadStatus}
                  ></PropertyCard>
               </div>
              );
            })}
             </div>
          </InfiniteScroll>
        </div> 
        {propertyId && (
            <CustomModal
              open={showPropertyModal}
              handleClose={handlePropertyModal}
              customClass="list-carousel"
            >
                  <CustomCarousel
                  handleNextProperty={handleNextProperty}
                  handlePrevProperty={handlePrevProperty}
                  listDetailRes={listDetailRes}
                  propertyIndex={propertyIndex}
                  >
              <PropertyDetails
                selectedPropertyId={propertyId}
                displayText={listDetail?.name}
                handleModalClose={handlePropertyModal}
                type={PropertyDetailsParentIdType.LEAD_ID}
              />
             </CustomCarousel>
            </CustomModal>
          )}
      </div>}
      </>
  );
};

export default ListDetail;
